<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
			<v-col cols="12" :sm="step === 2 ? '11' : '12'">
				<base-heading :title="title">
					<base-btn v-if="showMainReport" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
			</v-col>
			<v-col cols="12" sm="1" align="center">
        <base-btn v-if="step === 2" color="grey darken-3" text @click="close" icon="mdi-arrow-left" btnText="Atras"></base-btn>
      </v-col>
		</v-row>
		
		<v-row>
			<v-col cols="12">
				<v-window v-model="step" touchless>
					<v-window-item :value="1">
						<base-data-table 
							v-model="sortBy"
							desc
							:headers="customHeaders" 
							:items="sales" 
							@loadDataTable="toList"
							@newItem="step++"
						>
							<template v-slot:item.options="{ item }">
								<base-icon-btn color="info darken-1" @click="viewItem(item)" icon="mdi-eye" tooltip="Ver Detalle"></base-icon-btn>
								<base-icon-btn v-if="item.canCancel" color="red darken-1" @click="openCancelSale(item.id)" icon="mdi-cancel" tooltip="Anular"></base-icon-btn>
								<base-icon-btn v-if="item.statusId!==$Const.STATUS.CANCELLED" color="green darken-1" @click="reportView(item)" icon="mdi-file-chart" tooltip="Ver Informe"></base-icon-btn>
							</template>

							<template v-slot:item.status="{ item }">
								<template v-if="item.statusId===$Const.STATUS.REGISTERED">
									<span class="blue--text">{{item.status}}</span>
								</template>
								<template v-else-if="item.statusId===$Const.STATUS.APPROVED">
									<span class="success--text">{{item.status}}</span>
								</template>
								<template v-else>
									<span class="red--text">{{item.status}}</span>
								</template>
							</template>

							<template v-slot:item.paymentType="{ item }">
								<base-icon v-if="item.paymentType == 1" :color="item.statusId===$Const.STATUS.CANCELLED ? 'red' : 'green darken-1'" tooltip="Efectivo">mdi-cash</base-icon>
								<base-icon v-else-if="item.paymentType === 2" :color="item.statusId===$Const.STATUS.CANCELLED ? 'red' : 'info darken-1'" tooltip="Tajeta">mdi-credit-card-outline</base-icon>
								<template v-else-if="item.paymentType === 3">
									<base-icon v-if="item.creditStatusId===$Const.STATUS.CREDIT_PENDING" color="secondary" :tooltip="item.creditStatus">mdi-account-cash</base-icon>
									<base-icon v-else-if="item.creditStatusId===$Const.STATUS.CREDIT_COMPLETED" color="green darken-1" :tooltip="item.creditStatus">mdi-account-cash</base-icon>
									<base-icon v-else-if="item.creditStatusId===$Const.STATUS.CANCELLED" color="red" :tooltip="`Crédito ${item.creditStatus}`">mdi-account-cash</base-icon>
								</template>
							</template>

							<template v-slot:item.dateTime="{ item }">
								<base-form-date-list :dateTimeToShow="item.dateTime" 
									:showDialog="item.showDateTimeDialog"
									:formTitle="`Información venta: ${item.id}`"
									:headers="dateTimeHeaders"
									:items="item.dateTimeItems"
									@open="openDateTimeDialog(item)"
									@close="closeDateTimeDialog(item)"
								></base-form-date-list>
							</template>
						</base-data-table>

						<v-dialog v-model="showCancelSaleDialog" persistent scrollable max-width="800px">
							<base-form-simple ref="CancelSaleForm"
								formTitle="Anular venta"
								:formLoading="cancelSaleFormLoading"
								@cancelButton="closeCancelSale"
								@saveButton="saveCancelSale"
							>
								<template v-slot:formContent>
									<v-container class="no-padding" fluid>
										<v-row>
											<v-col cols="12" sm="3" md="3">
												<base-text-field v-model="editedSale.id" label="Número de Venta" readonly></base-text-field>
											</v-col>
											<v-col cols="12" sm="12" md="12">
												<base-textarea outlined v-model="editedSale.reason" label="Motivo" counter maxlength="250" 
												:rules="[commonValRules.required, validationRules.reasonRules.counter]" required></base-textarea>
											</v-col>
										</v-row>
									</v-container>
								</template>
							</base-form-simple>
						</v-dialog>
					</v-window-item>
					<v-window-item :value="2">
						<v-dialog v-model="showProductFinder" persistent scrollable max-width="1000px">
							<base-product-finder mode="Sales" @cancelButton="closeProductFinder" @addProduct="addDetail" @removeProduct="removeDetailFromFinder" :productsAdded="details.map(x => x.productId)"/>
						</v-dialog>

						<v-dialog v-model="showCustomerFinder" persistent scrollable max-width="1000px">
							<base-customer-finder @cancelButton="closeCustomerFinder" @selected="selectedCustomer"/>
						</v-dialog>

						<base-form-simple ref="SaleForm"
							:formTitle="saleFormTitle"
							:formLoading="formLoading"
							:hideSaveButton="viewDetails"
							@cancelButton="close"
							@saveButton="save"
						>
							<template v-slot:formContent>
								<v-row justify="center">
									<v-col v-if="showReason" cols="12">
										<v-alert
											text
											elevation="2"	
											color="red"
											transition="scale-transition"
										>
											<v-icon color="red" left>mdi-alert-circle</v-icon>
											<strong>La venta fue anulada por el siguiente motivo: </strong> {{editedItem.reason}}
										</v-alert>
									</v-col>

									<!-- Datos de la venta -->
									<v-col cols="12" sm="6" md="6">
										<base-form-container
											title="Datos de la venta"
											titleClasses="grey lighten-3"
										>
											<template v-slot:content>
												<v-row align="center" justify="center">
													<v-col v-if="viewDetails" cols="12" sm="6" md="4">
														<base-text-field v-model="editedItem.id" label="Nro. de Venta" readonly>
														</base-text-field>
													</v-col>
													<v-col v-if="viewDetails" cols="12" sm="6" md="4">
														<base-text-field v-model="editedItem.dateTime" label="Fecha y hora" readonly>
														</base-text-field>
													</v-col>
													<v-col v-if="viewDetails" cols="12" sm="6" md="4">
														<base-text-field v-model="editedItem.status" label="Estado" readonly>
														</base-text-field>
													</v-col>

													<v-col cols="12" sm="6" md="4">
														<base-text-field v-model="voucherType" label="Comprobante" readonly>
														</base-text-field>
													</v-col>

													<template v-if="showInvoiceData">
														<v-col cols="12" sm="6" md="4">
															<base-text-field v-model="editedItem.invoiceNumber" label="Factura N°" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<base-text-field v-model="editedItem.authorizationNumber" label="Autorización N°" readonly>
															</base-text-field>
														</v-col>
													</template>
												</v-row>
											</template>
										</base-form-container>
									</v-col>

									<!-- Datos del cliente -->
									<v-col cols="12" sm="6" md="6">
										<base-form-container
											title="Datos del cliente"
											titleClasses="grey lighten-3"
										>
											<template v-if="!viewDetails" v-slot:titleContent>
												<v-spacer></v-spacer>
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<v-icon class="mb-1"
															color="info darken-1"
															dark
															size="25"
															v-bind="attrs"
															v-on="on"
														>
															mdi-help-circle-outline
														</v-icon>
													</template>
													<span v-if="$vuetify.breakpoint.mdAndUp">Para un cliente <strong>SIN NOMBRE</strong> ingrese el numeral <strong>0</strong> y presione la tecla <strong>Enter</strong></span>
													<span v-else>Para un cliente <strong>SIN NOMBRE</strong> ingrese el numeral <strong>0</strong> y presione el botón <strong>Verificar</strong></span>
													<!-- <span>Es el código de identificación personal de la cuenta <strong>Global Desk</strong> de su cliente.</span> -->
												</v-tooltip>
											</template>

											<template v-slot:content>
												<v-row v-if="!viewDetails" dense align="center" justify="center">
													<v-col cols="9" sm="8" md="7" align="center">
														<!-- <v-tooltip v-if="$vuetify.breakpoint.mdAndUp" top>
															<template v-slot:activator="{ on }"> -->
																<!-- <base-text-field v-on="on" clearable @keyup.enter="fetchCustomer()" v-model="editedCustomer.gdpiCode" label="Código Global Desk" maxlength="30" prepend-icon="mdi-account-check" -->
																<base-text-field v-if="$vuetify.breakpoint.mdAndUp" clearable @keyup.enter="fetchCustomer()" v-model="editedCustomer.gdpiCode" label="Código Global Desk" maxlength="30" prepend-icon="mdi-account-check"
																:hint="gdpiCodeHint" placeholder="Ej: 102030">
																	<template v-slot:append>
																		<v-tooltip
																			top
																		>
																			<template v-slot:activator="{ on }">
																				<v-icon v-on="on" color="info darken-1">
																					mdi-help-circle-outline
																				</v-icon>
																			</template>
																			Es el código de identificación personal de la cuenta <strong>Global Desk</strong> de su cliente.
																		</v-tooltip>
																	</template>
																</base-text-field>
															<!-- </template>
															<span>Ingrese el código Global Desk de su cliente o el numeral '0' y presione la tecla 'Enter'</span>
														</v-tooltip> -->
														<template v-else>
															<base-text-field clearable v-model="editedCustomer.gdpiCode" label="Código Global Desk" maxlength="30"
															:hint="gdpiCodeHint" placeholder="Ej: 102030"></base-text-field>
														</template>
													</v-col>
													<v-col v-if="$vuetify.breakpoint.smAndDown" cols="3" sm="3" align="center">
														<base-icon-btn fabMode small btnColor="primary" color="white" @click="fetchCustomer()" icon="mdi-account-search" tooltip="Verificar" :disabled="!editedCustomer.isValid"></base-icon-btn>
													</v-col>
													<v-col cols="12" sm="6" md="5" align="center">
														<base-btn color="primary" @click="openCustomerFinder()" icon="mdi-account-details" btnText="Clientes"></base-btn>
													</v-col>
												</v-row>
												
												<v-row align="center" justify="center">
													<v-col cols="9" sm="8" md="7">
														<base-text-field v-if="viewDetails" v-model="editedItem.customer" label="Cliente" readonly>
														</base-text-field>
														<base-text-field v-else v-model="editedItem.customer" label="Cliente" placeholder="Nombre del cliente" readonly
														:rules="[commonValRules.required]" required></base-text-field>
													</v-col>
													<v-col cols="3" sm="4" md="5" align="center">
														<base-number-field v-if="viewDetails" v-model="editedItem.generalDiscount" label="Descuento" suffix="%" readonly>
														</base-number-field>
														<base-number-field v-else v-model="editedItem.generalDiscount" label="Descuento" maxlength="3"
														suffix="%" :rules="[validationRules.generalDiscountRules.counter, validGeneralDiscount()]" readonly></base-number-field>
													</v-col>
												</v-row>
											</template>
										</base-form-container>
									</v-col>

									<!-- Detalles de los productos -->
									<v-col cols="12">
										<base-form-container
											title="Productos"
											titleClasses="info darken-1 white--text"
										>
											<template v-slot:content>
												<v-row v-if="!viewDetails" dense align="center" justify="center">
													<v-col cols="9" sm="8" md="6" align="center">
														<v-tooltip v-if="$vuetify.breakpoint.mdAndUp" top>
															<template v-slot:activator="{ on }">
																<base-text-field v-on="on" clearable @keyup.enter="fetchProduct()" v-model="productCode" label="Código Producto" counter maxlength="20" prepend-icon="mdi-cart"
																:hint="productCodeHint"></base-text-field>
															</template>
															<span>Ingrese el código y presione la tecla 'Enter'</span>
														</v-tooltip>
														<template v-else>
															<base-text-field clearable v-model="productCode" label="Código Producto" counter maxlength="20"
															:hint="productCodeHint"></base-text-field>
														</template>
													</v-col>
													<v-col v-if="$vuetify.breakpoint.smAndDown" cols="3" sm="2" align="center">
														<base-icon-btn fabMode small btnColor="secondary" color="white" @click="fetchProduct()" icon="mdi-cart" tooltip="Agregar" :disabled="!productCodeIsValid"></base-icon-btn>
													</v-col>
													<v-col cols="12" sm="6" md="4" align="center">
														<base-btn color="primary" @click="openProductFinder()" icon="mdi-clipboard-text-search" btnText="Productos"></base-btn>
													</v-col>
												</v-row>

												<v-row align="center" justify="center">
													<v-col cols="12" align="center">
														<base-data-table ref="DtDetails"
															:headers="customDetailHeaders" 
															:items="details"
															sortBy="customCode"
															hideHeader
															:show-expand="showExpand"
															item-key="productId"
															elevation="0"
															showBorder
														>
															<template v-if="!viewDetails" v-slot:item.options="{ item }">
																<base-icon-btn :size="25" min-elevation="0" color="red darken-1" @click="removeDetail(item)" icon="mdi-close" tooltip="Eliminar Producto"></base-icon-btn>
																<base-icon-btn :size="25" min-elevation="0" color="secondary" @click="updateProduct(item)" icon="mdi-reload" tooltip="Actualizar Producto"></base-icon-btn>
															</template>

															<template v-slot:expanded-item="{ item }">
																<base-form-stock-details :ref="`StockForm${item.productId}`" :stockFormId="item.stockFormId" :title="`Detalles: ${item.product}`" :details="item.stockDetails" :mode="viewDetails ? 'ShowData' : 'ReduceStock'">
																</base-form-stock-details>
															</template>

															<template v-slot:item-data-table-expand="{ expand, isExpanded, item }">
																<v-expand-transition v-if="item.stockFormId !== $Const.STOCK_FORM.GENERAL">
																	<base-icon-btn v-if="isExpanded" :size="25" min-elevation="0" key="hide" @click="expand(closeStockFormDetails(isExpanded, item.productId))" color="red darken-1" icon="mdi-eye-off" tooltip="Ocultar Detalles"></base-icon-btn>
																	<base-icon-btn v-else :size="25" min-elevation="0" key="show" @click="expand(!isExpanded)" color="success darken-1" icon="mdi-eye" tooltip="Ver Detalles"></base-icon-btn>
																</v-expand-transition>
															</template>

															<template v-slot:item.image="{ item }">
																<div style="width:100px;">
																	<base-product-img v-model="item.image" :productId="item.productId"
																		class="my-2"
																		gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
																		aspect-ratio="1.5"
																		contain
																	>
																	</base-product-img>
																</div>
															</template>

															<template v-slot:item.quantity="{ item }">
																<base-number-field v-if="canEditStock(item.stockFormId)" v-model="item.quantity" maxlength="6"
																prepend-inner-icon="mdi-cart-variant" :rules="[validationRules.quantityRules.counter, validationRules.quantityRules.valid, validDetailQuantity(item.quantity, item.stock)]" required></base-number-field>
																
																<base-text-field v-else-if="!viewDetails" type="number" class="centered-input custom-max-width" :value="getStockSum(item)" maxlength="6"
																hint="Se actualiza desde los detalles"
																prepend-inner-icon="mdi-cart-variant" :rules="[commonValRules.required, validationRules.quantityRules.counter, validationRules.quantityRules.valid, validDetailQuantity(item.quantity, item.stock)]" required readonly></base-text-field>
																
																<template v-else>
																	<v-icon small>mdi-cart-variant</v-icon> {{ item.quantity }}
																</template>
															</template>

															<template v-slot:item.unitPrice="{ item }">
																Bs. {{ item.unitPrice }}
															</template>

															<template v-slot:item.subtotalPrice="{ item }">
																<template v-if="!viewDetails">
																	Bs. {{ (item.quantity * item.unitPrice).toFixed(2) }}
																</template>
																<template v-else>
																	Bs. {{ item.subtotalPrice }}
																</template>
															</template>

															<template v-slot:item.discount="{ item }">
																<base-currency-field v-if="!viewDetails"
																	v-model="item.discount"
																	:rules="[validationRules.productDiscountRules.minValue, validProductDiscount(item) ]" 
																	required
																></base-currency-field>
																<template v-else>
																	Bs. {{ item.discount }}
																</template>
															</template>

															<template v-slot:item.totalPrice="{ item }">
																<template v-if="!viewDetails">
																	Bs. {{ item.totalPrice=calculateTotalPriceOfProduct(item.quantity, item.unitPrice, item.discount)}}
																</template>
																<template v-else>
																	Bs. {{ item.totalPrice }}
																</template>
															</template>

															<template v-slot:no-data>
																<h3 class="red--text">*No hay productos agregados al detalle.</h3>
															</template>
														</base-data-table>
													</v-col>
												</v-row>
											</template>
										</base-form-container>
									</v-col>

									<!-- Datos finales -->
									<v-col cols="12" sm="6" order-sm="2" align="right">
										<base-form-container
											title="Datos finales"
											titleClasses="info darken-1 white--text"
										>
											<template v-slot:titleContent>
												<v-spacer></v-spacer>
												<v-badge color="red" overlap :content="calculateTotalProductsForBadge" :value="calculateTotalProductsForBadge">
													<v-icon dark>mdi-cart-variant</v-icon>
												</v-badge>
											</template>

											<template v-slot:content>
												<v-row align="center" justify="center" no-gutters>
													<v-col cols="6" sm="6" align="center">
														<h3 class="mt-6 mt-sm-0">
															<strong>Productos en Total</strong>
															<p class="mt-2 mb-0">{{calculateTotalProducts}}</p>
														</h3>
													</v-col>
													<v-col cols="6" sm="6" align="center">
														<h3>
															<strong>Sub Total</strong>
															<p class="mt-2 mb-0">Bs. 
																<template v-if="!viewDetails">
																	{{editedItem.subtotalPrice = calculateSubtotalPrice}}
																</template>
																<template v-else>
																	{{editedItem.subtotalPrice}}
																</template>
															</p>
														</h3>
													</v-col>
													<v-col cols="6" sm="6" align="center" class="pt-4">
														<h3>
															<strong>Descuento ({{editedItem.generalDiscount}}%)</strong>
															<p class="mt-2 mb-0">Bs. 
																	{{editedItem.totalDiscount = calculateTotalDiscount}}
															</p>
														</h3>
													</v-col>
													<v-col cols="6" sm="6" align="center" class="pt-4">
														<h3 class="mt-6 mt-sm-0">
															<strong>Total a Pagar</strong>
															<p class="mt-2 mb-0">Bs. 
																<template v-if="!viewDetails">
																	{{editedItem.totalPrice = (editedItem.subtotalPrice-editedItem.totalDiscount).toFixed(2)}}
																</template>
																<template v-else>
																	{{editedItem.totalPrice}}
																</template>
															</p>
														</h3>
													</v-col>
												</v-row>
											</template>
										</base-form-container>
									</v-col>

									<!-- Formulario de pagos -->
									<v-col cols="12" sm="6" order-sm="1">
										<base-form-payment-type ref="PaymentForm" v-model="paymentData" :totalPrice="editedItem.totalPrice" :disabledFields="viewDetails" :status="editedItem.creditStatusId">
										</base-form-payment-type>
									</v-col>

									<!-- Detalle de Facturación -->
									<v-col v-if="issueInvoices" cols="12" sm="6" order-sm="3">
										<base-form-container
											title="Facturación"
											titleClasses="secondary accent-4 white--text"
										>
											<template v-slot:content>
												<v-row v-if="!viewDetails" dense align="center" justify="center">
													<v-col cols="12" sm="4" align="center">
														<v-tooltip v-if="$vuetify.breakpoint.mdAndUp" top>
															<template v-slot:activator="{ on }">
																<base-number-field v-on="on" fullWidth @keyup.enter="fetchNit()" label="NIT/CI" v-model="editedItem.nit" counter maxlength="20"
																placeholder="0"
																:rules="[validationRules.nitRules.counter]" :hint="nitHint" required :readonly="viewDetails"></base-number-field>
															</template>
															<span>Ingrese el NIT o el numeral '0' y presione la tecla 'Enter'</span>
														</v-tooltip>
														<template v-else>
															<base-number-field fullWidth v-model="editedItem.nit" label="NIT/CI" counter maxlength="20"
															placeholder="0"
															:rules="[validationRules.nitRules.counter]" :hint="nitHint" required :readonly="viewDetails"></base-number-field>
														</template>
													</v-col>
													<v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" sm="4" align="center">
														<base-icon-btn fabMode small btnColor="secondary" color="white" @click="fetchNit()" icon="mdi-account-check" tooltip="Verificar" :disabled="!validNit"></base-icon-btn>
													</v-col>
													<v-col cols="12" :sm="$vuetify.breakpoint.mdAndUp ? '8' : '10'" align="center">
														<base-text-field clearable class="uppercase-input" v-model="editedItem.businessName" label="Razon Social" counter maxlength="150"
														placeholder="A nombre de ..."
														:rules="[commonValRules.required, validationRules.businessNameRules.counter]" required :readonly="viewDetails"></base-text-field>
													</v-col>
												</v-row>

												<v-row v-else align="center" justify="center">
													<v-col cols="12" sm="5" md="5">
														<base-number-field fullWidth v-model="editedItem.nit" label="NIT/CI" readonly>
														</base-number-field>
													</v-col>
													<v-col cols="12" sm="7" md="7">
														<base-text-field class="uppercase-input" v-model="editedItem.businessName" label="Razon Social" readonly>
														</base-text-field>
													</v-col>
												</v-row>
											</template>
										</base-form-container>
									</v-col>

									<!-- Errores -->
									<v-scale-transition mode="out-in">
										<v-col v-if="errors.length > 0" cols="12" order-sm="5">
											<v-alert
												text
												elevation="2"	
												color="red"
												transition="scale-transition"
											>
												<p><v-icon color="red" left>mdi-alert-circle</v-icon><b>¡ATENCIÓN!</b></p>
												<p class="mb-1" v-for="(error, i) in errors" :key="i">
													<v-icon color="red">mdi-circle-medium</v-icon> <span v-html="error"></span>
												</p>
											</v-alert>
										</v-col>
									</v-scale-transition>
								</v-row>
							</template>
						</base-form-simple>
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>

		<base-pdf-viewer :show="showPdfViewer" :title="reportFile.title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
			title: 'Punto de Venta',
			step: 1,
			errors: [],
			sortBy: 'dateTime',
			headers: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Nro. Venta", value: "id" },
        { text: "Usuario", value: "userFullName" },
				{ text: "Cliente", value: "customer" },
				{ text: "Tipo Comprobante", value: "voucherType" },
				{ text: "Nro. Factura", value: "invoiceNumber", sortable: false},
				{ text: "Nro. Autorización", value: "authorizationNumber", sortable: false},
				{ text: "Fecha", value: "dateTime" },
				{ text: "Total Bs.", value: "totalPrice", sortable: false},
				{ text: "Tipo Pago", value: "paymentType" },
        { text: "Estado", value: "status" }
			],
			sales: [],
			detailHeaders: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Imagen", value: "image", sortable: false },
				{ text: "Código", value: "customCode", sortable: false },
				{ text: "Producto", value: "product", sortable: false },
				{ text: "Stock", value: "stock", sortable: false },
        { text: "Cantidad", value: "quantity", sortable: false },
				{ text: "Precio Unit", value: "unitPrice", sortable: false },
				{ text: "Subtotal", value: "subtotalPrice", sortable: false },
				{ text: "Descuento", value: "discount", sortable: false },
				{ text: "Total", value: "totalPrice", sortable: false },
				{ text: 'Detalles', value: 'data-table-expand', sortable: false },
			],
			details: [],
			showExpand: false,
			editedItem: {
				id: '',
				customerId:0,
				customer:'',
				voucherTypeId:'',
				invoiceNumber:'',
				authorizationNumber:'',
				nit:'',
				businessName:'',
				subtotalPrice: 0,
				generalDiscount: 0,
				totalDiscount: 0,
				totalPrice: 0,
				dateTime:'',
				statusId: 0,
				status: '',
				creditStatusId: 0
      },
      defaultItem: {
       	id: '',
				customerId:0,
				customer:'',
				voucherTypeId:'',
				invoiceNumber:'',
				authorizationNumber:'',
				nit:'',
				businessName:'',
				subtotalPrice: 0,
				generalDiscount: 0,
				totalDiscount: 0,
				totalPrice: 0,
				dateTime:'',
				statusId: 0,
				status: '',
				creditStatusId: 0
			},
			//PAYMENT DATA
			paymentData: {
				paymentTypeId: 1,
				amountPaid: '',
				cardNumber:'',
				nextPaymentDate:'',
				creditPaymentTypeId: 1
			},
			//PRODUCT
			productCode: '',
			productCodeIsValid:false,
			showProductFinder:false,
			//CUSTOMER
			editedCustomer: {
				gdpiCode:'',
				isValid:false,
			},
			defaultCustomer: {
				gdpiCode:'',
				isValid:false,
			},
			showCustomerFinder:false,

			formLoading: false,
			viewDetails:false,
			validNit: false,
			//SALE TO CANCEL
			editedSale: {
				id: '',
				reason: ''
      },
      defaultSale: {
       	id: '',
				reason: ''
			},
			showCancelSaleDialog: false,
			cancelSaleFormLoading: false,

			//SALE DATE TIMES
			dateTimeHeaders: [
				{ text: "Nro", value: "row", sortable: false },
				{ text: "Usuario", value: "userFullName", sortable: false },
				{ text: "Fecha y hora", value: "dateTime", sortable: false },
        { text: "Estado", value: "status", sortable: false },
			],

      validationRules: {
				gdpiCodeRules: {
          counter: value => (!!value &&  value.length >= 1 && value.length <= 30) || 'No debe tener más de 30 caracteres, ni menos de 1 caracter.',
				},
				generalDiscountRules: {
					counter: value => (value != null && value.toString().length <= 3) || 'No debe tener más de 3 caracteres.',
					valid: value => (parseInt(value) >= 0 && parseInt(value) <= 100) || 'El valor debe ser entre 0 y 100.',
				},

				nitRules: {
          counter: value => (value != null && value.toString().length >= 1 && value.toString().length <= 20) || 'No debe tener más de 20 caracteres, ni menos de 1 caracter.',
				},
				businessNameRules: {
          counter: value => (value != null && value.length <= 150) || 'No debe tener más de 150 caracteres.',
				},
				commentsRules: {
          counter: value => (value.length <= 250) || 'No debe tener más de 250 caracteres.',
				},

				productCodeRules: {
					counter: value => (!!value && value.length >= 4 && value.length <= 20) || 'No debe tener más de 20 caracteres, ni menos de 4 caracteres.',
				},


				quantityRules: {
					counter: value => (value != null && value.toString().length <= 6) || 'No debe tener más de 6 caracteres.',
          valid: value => (parseInt(value) > 0) || 'No puede ser menor a 1.',
				},
				productDiscountRules: {
          minValue: value => (parseFloat(value).toFixed(2) >= 0) || 'No puede ser menor a 0.',
				},

				reasonRules: {
					counter: value => (!!value && value.length >= 10 && value.length <= 250) || 'No debe tener más de 250 caracteres, ni menos de 10 caracteres.',
				},
			},

			//PDF comprobante
			showPdfViewer: false,
			reportFile:{
				title: '',
				saleId:'',
				fileName:'',
				fileContent:''
			},
			defaultReportFile:{
				title: '',
				saleId:'',
				fileName:'',
				fileContent:''
			},
		}
	},

	computed: {
		saleFormTitle() {
			return this.viewDetails ? 'Venta' : 'Nueva venta';
		},

		showMainReport(){
			if (this.step === 2 || this.sales.length<=0) {
				return false;	
			}
			return true;
		},

		issueInvoices(){
			if (this.viewDetails) {
				return this.editedItem.voucherTypeId === 1;
			}
			else {
				return this.companyData.enableInvoice;
			}
		},

		voucherType(){
			return this.issueInvoices ? 'Factura' : 'Recibo';
		},

		showInvoiceData(){
			if (this.issueInvoices && this.viewDetails) {
				return true;
			}
			return false;
		},

		customHeaders(){
			if (this.companyData.enableInvoice) {
				return this.headers;
			}

			let result = this.headers.filter(x => x.value != 'invoiceNumber' && x.value != 'authorizationNumber');
			return result;
		},

		customDetailHeaders(){
			var excludes = [];
			if (this.viewDetails) {
				excludes.push('options');
				excludes.push('stock');
			}

			this.showExpand = true;
			if (!this.details.some(x => x.stockFormId != this.$Const.STOCK_FORM.GENERAL)) {
				this.showExpand = false;
				excludes.push('data-table-expand');
			}

			if (excludes.length <= 0) {
				return this.detailHeaders;
			}
			return this.detailHeaders.filter(x => !excludes.includes(x.value));
		},

		gdpiCodeHint(){
			var result = this.validationRules.gdpiCodeRules.counter(this.editedCustomer.gdpiCode);
			if (result == true) {
				this.editedCustomer.isValid = true;
				return '';
			}
			else {
				this.editedCustomer.isValid = false;
				return result;
			}
		},

		productCodeHint(){
			var result = this.validationRules.productCodeRules.counter(this.productCode);
			if (result == true) {
				this.productCodeIsValid = true;
				return '';
			}
			else {
				this.productCodeIsValid = false;
				return result;
			}
		},

		nitHint(){
			this.validNit = false;
			var result = this.validationRules.nitRules.counter(this.editedItem.nit);
			if (typeof(result) == 'string') {
				return result;
			}
			this.validNit = true;
			return '';
		},

		calculateTotalProducts(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? result : 0;
		},

		calculateTotalProductsForBadge(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? (result > 99 ? result = '+99' : result) : 0;
		},

		calculateSubtotalPrice(){
			var result=0.0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseFloat(element.totalPrice);
			}
			return parseFloat(result).toFixed(2);
		},

		calculateTotalDiscount(){
			var result=0.0;
			if (this.editedItem.generalDiscount > 0) {
				result = (this.editedItem.subtotalPrice * (this.editedItem.generalDiscount / 100));
			}
			return parseFloat(result).toFixed(2);
		},

		showReason(){
			var result = this.viewDetails && this.editedItem.statusId===this.$Const.STATUS.CANCELLED;
			return result;
		}
	},

	created() {
		this.toList();
  },

	methods: {
		async toList(){
			let me=this;
			try {
        me.SHOW_LOADING()
				me.sales=[];
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId
				};
				await me.getObjectResponse('api/CompanySale/GetAllByBranchOffice', request).then(data => {
					if (data !== undefined) {
						me.sales = me.addCustomProperties(data.sales);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		addCustomProperties(originalSales) {
			let sales = originalSales;
			sales.map(x => {
				x.showDateTimeDialog = false;
				x.dateTimeItems = [];
			});
			return sales;
		},

		async fetchCustomer(){
			let me=this;
			if (!me.editedCustomer.isValid) {
				return;
			}

			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'gdpi': me.editedCustomer.gdpiCode,
				};
				me.editedItem.customerId = 0;
				me.editedItem.customer = '';
				await me.getObjectResponse('api/Customer/VerifyCustomer', request).then(data => {
					if (data !== undefined) {
						me.editedItem.customerId = data.customer.id;
						me.editedItem.customer = data.customer.fullName;
						me.editedItem.generalDiscount = data.customer.generalDiscount;
						me.editedCustomer.gdpiCode='';
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false
      }
		},

		openCustomerFinder(){
			this.showCustomerFinder=true;
    },

    closeCustomerFinder() {
			this.showCustomerFinder=false;
			this.editedCustomer.gdpiCode='';
		},
		
		selectedCustomer(data = {}){
			this.editedItem.customerId = data.id;
			this.editedItem.customer = data.fullName;
			this.editedItem.generalDiscount = data.generalDiscount;
			this.closeCustomerFinder();
		},
		
		async fetchProduct(){
			let me=this;
			if (!me.productCodeIsValid) {
				return;
			}
			if (me.details.some(x => x.customCode == me.productCode)) {
				me.$swal(me.swalConfig.errorTitle, 'El producto ya se encuentra agregado.', 'error');
				return;
			}
			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'code': me.productCode,
				};
				await me.getObjectResponse('api/ProductInventory/GetProductByCode', request).then(data => {
					if (data !== undefined) {
						me.addDetail(data.product);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		async updateProduct(item){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				const lastImage = item.image;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'code': item.customCode,
				};
				await me.getObjectResponse('api/ProductInventory/GetProductByCode', request).then(data => {
					if (data !== undefined) {
						me.updateDetail(data.product, lastImage);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		openProductFinder(){
			this.showProductFinder=true;
    },

    closeProductFinder() {
			this.showProductFinder=false;
			this.productCode='';
    },

		addDetail(data = []){
			if (this.details.some(x => x.productId == data['id'])) {
				this.$swal(this.swalConfig.errorTitle, 'El producto ya se encuentra agregado.', 'error');
				return;
			}

			let stockFormId = parseInt(data['stockFormId'], 10)
			let stockDetails = this.parseStockDetails(data['stockDetails'], stockFormId, false)
			this.details.push({
				productId: data['id'], 
				image: data['image'], 
				customCode: data['customCode'], 
				product: data['visualName'], 
				stock: parseInt(data['stock'], 10),
				quantity: 0, 
				unitPrice: data['salePricePerUnit'],
				discount: 0,
				totalPrice: 0,

				stockFormId: stockFormId,
				stockDetails: stockDetails
			});
			this.productCode="";
		},

		updateDetail(data = [], image) {
			let me=this;
			let stockFormId = parseInt(data['stockFormId'], 10)
			let stockDetails = me.parseStockDetails(data['stockDetails'], stockFormId, false)
			const newItem = {
				productId: data['id'], 
				image: image,
				customCode: data['customCode'], 
				product: data['visualName'], 
				stock: parseInt(data['stock'], 10),
				quantity: 0, 
				unitPrice: data['salePricePerUnit'],
				discount: 0,
				totalPrice: 0,

				stockFormId: stockFormId,
				stockDetails: stockDetails
			};

			const index = me.details.findIndex((x) => x.productId === newItem.productId);
			me.details.splice(index, 1, newItem);
		},

		parseStockDetails(stockDetails, stockFormId, serialize) {
			if (serialize) {
				if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
					return null;
				}
				let stockDetailsResult = stockDetails.filter(f => f.stock !== null && f.stock && parseInt(f.stock) > 0).map(x => x);
				stockDetailsResult = stockDetailsResult.map((x) => {
					const res  = Object.fromEntries(Object.entries(x).filter(e=>e[0]!=='originalStock'));
					return res;
				});
				var result = JSON.stringify(stockDetailsResult)
				return result;
			} else {
				if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
					return [];
				}
				var result = JSON.parse(stockDetails)
				if (this.viewDetails) {
					return result;
				}
			
				for (let i = 0; i < result.length; i++) {
					const detail = result[i];
					detail.originalStock = detail.stock
					detail.stock = 0
				}
				return result;
			}
		},

		canEditStock(stockFormId) {
			if (this.viewDetails) {
				return false;
			} else if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
				return true;
			} else {
				return false;
			}
		},

		getStockSum(item){
			if (this.viewDetails) {
					return item.quantity;
			}

			let total = 0;
			const stockArray = item.stockDetails.filter(f => f.stock !== null && f.stock).map(x => parseInt(x.stock))
			if (stockArray.length > 0) {
				const sum = stockArray.reduce((a,b) => a+b)
				total += sum	
			}
			item.quantity = total;
			return total;
		},

		removeDetail(item){
			const index = this.details.indexOf(item);
      if (index !== -1) {
				this.details.splice(index, 1);
			}
		},

		removeDetailFromFinder(data = []){
			const index = this.details.findIndex(x => x.productId == data['id']);
      if (index !== -1) {
				this.details.splice(index, 1);
			}
		},

		setDetails(details){
			var result = [];
			for (let i = 0; i < details.length; i++) {
				const element = details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var stockDetails = this.parseStockDetails(element.stockDetails, stockFormId, false)
				result.push({
					productId: element.productId,
					image: null,
					customCode: element.customCode,
					product: element.product,
					quantity: parseInt(element.quantity, 10), 
					unitPrice: parseFloat(element.unitPrice).toFixed(2),
					subtotalPrice: parseFloat(element.subtotalPrice).toFixed(2),
					discount: parseFloat(element.discount).toFixed(2), 
					totalPrice: parseFloat(element.totalPrice).toFixed(2),

					stockFormId: stockFormId,
					stockDetails: stockDetails
				});
			}
			return result;
		},

		getDetails(){
			var result = [];
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var stockDetails = this.parseStockDetails(element.stockDetails, stockFormId, true)
				result.push({
					productId: element.productId, 
					quantity: parseInt(element.quantity, 10), 
					unitPrice: parseFloat(element.unitPrice),
					discount: parseFloat(element.discount),
					stockDetails: stockDetails,
				});
			}
			return result;
		},

		closeStockFormDetails(isExpanded, productId) {
			if (this.viewDetails) {
				return !isExpanded;
			}
			
			if (!this.$refs[`StockForm${productId}`].validate()) {
				return isExpanded;
			}
			return !isExpanded;
		},

		validateAllStockFormDetails(){
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				const formName = `StockForm${element.productId}`;
				var form = this.$refs[formName];
				if (form) {
					if (!form.validate()) {
						return false;
					}
				}
			}
			return true;
		},

		validDetailQuantity(quantity, stock){
			if (quantity > stock) {
				return 'No puede ser mayor al stock.'
			}
			return true;
		},

		validGeneralDiscount(){
			if (parseInt(this.editedItem.generalDiscount) >= 0 && parseInt(this.editedItem.generalDiscount) <= 100) {
				return true;	
			}
			this.editedItem.generalDiscount = '';
			return 'El valor debe ser entre 0 y 100.'
		},

		validProductDiscount(item){
			var totalPrice = (item.quantity * parseFloat(item.unitPrice)).toFixed(2);
			var discount = parseFloat(item.discount).toFixed(2);
			var remaining = (totalPrice - discount).toFixed(2);
			if (remaining > 0) {
				return true;
			}
			return 'Debe ser menor al subtotal.'
		},

		calculateTotalPriceOfProduct(quantity, unitPrice, discount){
			var totalPrice = (quantity * unitPrice).toFixed(2);
			if (discount && parseFloat(discount).toFixed(2) > 0) {
				totalPrice = (totalPrice - discount).toFixed(2);
			}
			return totalPrice;
		},

		async fetchNit(){
			let me=this;
			if (!me.validNit) {
				return;
			}

			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'nit': me.editedItem.nit,
				};
				me.editedItem.businessName = '';
				await me.getObjectResponse('api/CustomerInvoiceData/Verify', request).then(data => {
					if (data !== undefined) {
						me.editedItem.businessName = data.businessName;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		async viewItem(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'id': item.id,
				};
				await me.getObjectResponse('api/CompanySale/GetById', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.viewDetails=true;
					me.editedItem = Object.assign({}, data.sale);
					me.details = me.setDetails(data.sale.details);
					me.paymentData = Object.assign({}, 
					{
						paymentTypeId: data.sale.paymentTypeId,
						amountPaid: data.sale.amountPaid,
						cardNumber: data.sale.cardNumber,
						nextPaymentDate: data.sale.nextPaymentDate
					});
					me.step++;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    close() {
			this.step=1;
			this.clean();
    },
    
    clean(){
			this.$refs.DtDetails.collapseAll();
			this.$refs.SaleForm.reset();
			this.$refs.PaymentForm.reset();
			this.editedItem = Object.assign({}, this.defaultItem);
			this.editedCustomer = Object.assign({}, this.defaultCustomer);
			this.productCode="";
			this.details = [];
			this.viewDetails=false;
			this.validNit=false;
			this.errors = [];
    },

		customerPaymentTypeIsValid() {
			if (this.editedItem.customerId == 0 && this.paymentData.paymentTypeId != 1) {
				this.errors.push('El cliente <b>SIN NOMBRE</b> solo esta permitido para pagos en efectivo. Cambie el tipo de pago o ingrese un cliente diferente.')
				return false;
			}
			return true;
		},

    async save() {
			let me=this;
			me.errors = [];

			if (me.details.length===0) {
				return;
			}

			if (!me.validateAllStockFormDetails()) {
				return;
			}

			if (!me.customerPaymentTypeIsValid()) {
				return;
			}

			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'customerId': parseInt(me.editedItem.customerId, 10),
					'nit': me.editedItem.nit,
					'businessName': me.editedItem.businessName,
					'generalDiscount': parseInt(me.editedItem.generalDiscount, 10),
					'paymentTypeId': parseInt(me.paymentData.paymentTypeId, 10),
					'amountPaid': parseFloat(me.paymentData.amountPaid),
					'cardNumber': me.paymentData.cardNumber,
					'nextPaymentDate': me.paymentData.nextPaymentDate,
					'creditPaymentTypeId': parseInt(me.paymentData.creditPaymentTypeId),
					'details': me.getDetails(),
				};
				await me.getObjectResponse('api/CompanySale/Create', request).then(data => {
					if (data === undefined) {
						return;
					}

					if (data.code == 12) {
						me.errors = data.message;
						return;
					}
					
					me.addOrUpdateListItem(data.sale)
					if (data.fileContent != null) {
						me.reportFile.title = `Venta ${data.sale.id}`;
						me.reportFile.saleId = data.sale.id;
						me.reportFile.fileName = data.fileName;
						me.reportFile.fileContent = data.fileContent;
						me.showPdfViewer=true;
					} else {
						me.close();
					}
					me.showSuccessModal();
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
    },

		addOrUpdateListItem(item){
			let me=this;
			item['showDateTimeDialog'] = false;
			item['dateTimeItems'] = [];
			if (!!me.sales.find((x) => x.id === item.id)) {
				const index = me.sales.findIndex((x) => x.id === item.id)
				me.sales.splice(index, 1, item)
			} else {
				me.sales.push(item);
			}
		},

		openCancelSale(id){
			this.editedSale.id = id;
			this.showCancelSaleDialog = true;
		},

		closeCancelSale(){
			this.showCancelSaleDialog = false;
			this.$refs.CancelSaleForm.reset();
      this.editedSale = Object.assign({}, this.defaultSale);
		},
		
		async saveCancelSale(){
			let me=this;
			if (!await me.showConfirmationDialog(`Estás a punto de anular la venta, ésta acción no será revertida.`, `Si, anular la venta!`)) {
				me.closeCancelSale();
				return;
			}

			try {
				me.SHOW_LOADING()
				me.cancelSaleFormLoading = true
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'id': me.editedSale.id,
					'reason': me.editedSale.reason,
				};
				await me.getObjectResponse('api/CompanySale/Cancel', request).then(data => {
					if (data === undefined) {
						return;
					}

					me.addOrUpdateListItem(data.sale);
					me.closeCancelSale();
					me.showSuccessModal();
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.cancelSaleFormLoading = false
      }
		},

		async openDateTimeDialog(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				item.dateTimeItems=[];
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'id': item.id,
				};

				if(item.statusId==me.$Const.STATUS.CANCELLED)
				{
					me.dateTimeHeaders.push({ text: "Motivo", value: "reason", sortable: false });
				}

				await me.getObjectResponse('api/CompanySale/GetDatesById', request).then(data => {
					if (data !== undefined) {
						item.dateTimeItems = data.dateTimeList;
						item.showDateTimeDialog=true;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    closeDateTimeDialog(item) {
			item.showDateTimeDialog=false;
			item.dateTimeItems=[];
			if(item.statusId==this.$Const.STATUS.CANCELLED)
			{
				const index = this.dateTimeHeaders.indexOf(x => x.value == "reason");
				this.dateTimeHeaders.splice(index, 1);
			}
    },

		async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
				};
				await me.getObjectResponse('api/CompanySale/GetMainReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = me.title;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		async reportView(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'id': item.id,
					'getCopy': false
				};
				await me.getObjectResponse('api/CompanySale/GetReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = `Venta ${me.reportFile.saleId}`;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
			if (this.step===2) {
				this.close();
			}
		},
	},
}
</script>